import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Product } from '../Model/prodduct';
import { getPosts, getProductbyCateories } from '../Services/GetUser.service';
import ProductCard from '../component/ProductView';
import Navbar from '../component/Navbar';
import { reload } from 'firebase/auth';

function AllProductitem() {
    const [prod, setProd] = useState<Product[]>([]);
    const [filteredProd, setFilteredProd] = useState<Product[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState("");
    const navigate = useNavigate()
    const [loading, setloading] = useState(true);
    const getProducts = async() => {
        setloading(true)
        await getPosts("", (result: Product[]) => {
          setProd(result);
          setFilteredProd(result);
          const pages = Math.ceil(result.length / itemsPerPage);
          setTotalPages(pages);
        });
        setloading(false)
      };
    
      useEffect(() => {
        getProducts();
      }, []);
    
      const handleBuyNow = (item: Product) => {
        navigate(`/product/brands/checkout/${item.id}`);
      };
      
      const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const category = e.target.value;
        setSelectedCategory(category);
        const filtered = prod.filter(
          (item) => category === "" || item.productCategory === category
        );
        setFilteredProd(filtered);
        setTotalPages(Math.ceil(filtered.length / itemsPerPage));
        setCurrentPage(1); // Reset to the first page after filtering
      };
    
      const handlePageChange = (page: number) => {
        setCurrentPage(page);
      };
    
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const displayedProducts = filteredProd.slice(startIndex, endIndex);
      const brandNames = ["Hermès", "Chanel", "Zara", "Dior", "Gucci", "Louis-Vuitton"];

    const handleSend = (e:any)=>{
        navigate(`/get-product/${e}`)
        window.location.reload()
    }
  return (
   <>
   {loading && <><div className="h-screen flex justify-center items-center">Please Wait ....</div></>}
   {!loading &&  <div>
        <Navbar/>
      {/* Filter Dropdown */}
      <div className="px-5 my-4 flex justify-between items-center py-4 ">
        <div>
        <select
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-3000"
              id="grid-state"
              required={true}
              onChange={(event) => handleSend(event.target.value)}
            >
              <option value="">Select category</option>
              {brandNames.map((item)=>{
                return <option value={item}>{item}</option>
              })}
            </select>
        </div>
      </div>

      {/* Product Display with Pagination */}
      <div className="p-2">
        <div className="grid grid-cols-1 md:grid-cols-5 lg-grid-cols-5 gap-5">
          {displayedProducts.map((item: Product, id: number) => (
            <div className="" key={id}>
              <ProductCard
                imageUrl={item.productImage[0]}
                productName={item.productName}
                productPrice={item.productPrice}
                onBuyNow={() => handleBuyNow(item)}
              />
            </div>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center my-4">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`mx-1 p-2 rounded ${currentPage === index + 1 ? "bg-blue-900 text-white" : "bg-gray-300"}`}
            >
              {index + 1}
            </button>
          ))}
          {currentPage < totalPages && (
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="ml-4 bg-blue-900 text-white p-2 rounded"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>}
   </>
  )
}

export default AllProductitem
// src/components/LuxuryBagsPro.tsx
import React from "react";
import "../App.css";

const LuxuryBagsPro: React.FC = () => {
  return (
    <>
      <div className="min-h-screen">
        <div className="text-center my-16 font-extrabold text-blue-900 text-sm md:text-[1.5rem]">
          CONSIGN YOUR LUXURY BAGS WITH US
        </div>
        <div className="max-w-[90%] m-auto my-10  relative">
          <div className="flex  flex-col md:flex-row items-center gap-10 ">
            <div className=" w-full place-content-end">
              <img src="/image/r.jpg" alt="" className="h-[40vh]" />
            </div>
            <div className="w-full">
              <p className="leading-8">
                Welcome to Luxury Bags Pro, where consigning your luxury bags is
                simple and rewarding. Whether you're refreshing your collection
                or exploring the market, trust us to handle your prized pieces
                with care and expertise.
              </p>
              <p className="leading-8">
                We understand the value and prestige of designer handbags, and
                we offer a seamless, trusted platform for you to consign your
                beloved pieces. Whether you're looking to refresh your
                collection, make room for new additions, or simply explore the
                market, we're here to make the process effortless and rewarding.
              </p>
            </div>
          </div>
          <br />
          <div className="lg:absolute top-[220px] right-[100px] flex md:flex-row  flex-col items-center gap-10 ">
            <div className="w-full place-content-end">
              <img src="/image/r2.jpg" alt="" className="w-[250px]" />
            </div>
            <div className=" w-full mt-6 md:mt-20">
              <h2 className="text-lg font-semibold mb-2 text-gray-800">
                Why Choose Us?
              </h2>
              <ul className="list-disc list-inside text-gray-700">
                <li>
                  <span className="font-semibold">Expertise:</span> With years
                  in luxury fashion, we price your bags competitively for
                  maximum value.
                </li>
                <li className="mt-2">
                  <span className="font-semibold">Exposure:</span> We showcase
                  your bags globally through our platform and partnerships.
                </li>
                <li className="mt-2">
                  <span className="font-semibold">Transparency:</span> Stay
                  informed with regular updates from evaluation to sale.
                </li>
                <li className="mt-2">
                  <span className="font-semibold">Security:</span> Your bags are
                  shipped safely with comprehensive insurance coverage.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto p-8">
        <h2 className="text-2xl font-bold text-blue-900 text-center mb-4">HOW IT WORKS</h2>
        <ol className="list-decimal list-inside mb-8">
          <li className="mb-2">
            <span className="font-bold">Submit Your Bags:</span> Provide details
            and photos online or visit us for a consultation.
          </li>
          <li className="mb-2">
            <span className="font-bold">Evaluation:</span> We assess based on
            brand, condition, and market demand.
          </li>
          <li className="mb-2">
            <span className="font-bold">Agreement:</span> Sign our consignment
            terms, including pricing and commission.
          </li>
          <li className="mb-2">
            <span className="font-bold">Sell:</span> We contact you to tell us
            how you wish to be paid once your bags sell.
          </li>
        </ol>
        <p className="mb-4">
          Get Started Today! <br />
          Join others who trust us to consign their luxury bags. Start by
          filling our online form or visiting our boutique.
        </p>
        <p className="mb-4">
          Let us help you find a new home for your luxury bags while you enjoy
          the convenience and benefits of consignment.
        </p>
        <p className="mb-8">
          Discover a seamless way to consign your luxury bags with Luxury Bags
          Pro. Unlock value and space in your collection effortlessly.
        </p>
        <p className="text-center">
          For questions, visit our "Contact" page to reach out today.
        </p>
      </div>
    </>
  );
};

export default LuxuryBagsPro;

import React, { useState, useEffect } from 'react';

interface Testimonial {
  text: string;
  name: string;
}

const testimonials: Testimonial[] = [
  {
    text: "Luxury Bags Pro is the only retailer where you get exclusive deals at pocket friendly prices.",
    name: "Sarah Smith"
  },
  {
    text: "Smooth purchase process and excellent customer service. Will definitely shop here again!",
    name: "Becky Davo"
  },
  {
    text: "The Kelly I purchased exceeded my expectations. Ordering was easy, and delivery was prompt.",
    name: "Cindy Adrian"
  },
  {
    text: "I'm impressed with my Hermes Birkin 35 I purchased from Luxury Bags Pro. It's perfect for any occasion and the bag arrived quickly. Highly recommend!",
    name: "Daniel Novak"
  },
  {
    text: "My Chanel flap bag is not just a bag but a statement of elegance, this purchase has elevated my wardrobe",
    name: "Theodore Simons"
  }
];

const TestimonialCarousel: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000); // Change testimonial every 5 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  return (
    <div className="max-w-xl mx-auto p-4">
      <div className="bg-white p-6 rounded-lg">
        <p className="text-lg italic mb-4">"{testimonials[currentIndex].text}"</p>
        <p className="text-right font-semibold">- {testimonials[currentIndex].name}</p>
      </div>
    </div>
  );
};

export default TestimonialCarousel;

import Navbar from "../component/Navbar";
import Banner from "../component/Banner";
import ProductCard from "../component/ProductView";
import Curbside from "../component/Curbside";
import Footer from "../component/Footer";
import { useEffect, useState } from "react";
import { getPosts } from "../Services/GetUser.service";
import { Product } from "../Model/prodduct";
import { Link, useNavigate } from "react-router-dom";

function Home() {
  const [prod, setprod] = useState<Product[]>();
  const [loading, setloading] =  useState(true)
  const navigate = useNavigate()
  const getProducts = async() => {
    setloading(true)
   await getPosts("", (result: Product[]) => {
      const queryuser = result;
      setprod(queryuser);
    });
    setloading(false)
  };
  useEffect(() => {
    getProducts();
  }, []);

  // enter path to image when ever you want to change the banner image
  const images = ["/image/b1.jpg", "/image/b2.jpg", "/image/b3.jpg"];
  const images2 = ["/image/b1.jpg", "/image/b2.jpg", "/image/b3.jpg"];
  const handleBuyNow = (item:Product) => {
  navigate(`/product/brands/checkout/${item.id}`)
  };
  return (
   <>
   {loading && <> <div className="h-screen flex justify-center items-center">Please Wait ....</div></> }
   {!loading && <> 
    <div>
      <Navbar />
      <Banner images={images} images2={images2} />
      <div className="p-2">
        <div className=" flex justify-center items-center gap-6 md:gap-16 lg:gap-24 flex-wrap">
          <div className="">
            <div className="text-center">
              <p className="my-7 font-bold">SALE OF LUXURY BAG</p>
              <img src="/image/l.jpg" alt=""  className="w-[40vh] h-[30vh]"/>
            </div>
          </div>

          <div className="text-center">
            <div className="">
              <p className="my-7 font-bold">CONSIGN YOUR BAGS</p>
              <img src="/image/l2.jpg" alt=""  className="w-[40vh] h-[30vh]"/>
            </div>
          </div>

          <div className="text-center">
            <div className="">
              <p className="my-7 font-bold">EXCLUSIVE DEALS AND OFFERS</p>
              <img src="/image/l3.jpg" alt=""  className="w-[40vh] h-[30vh]"/>
            </div>
          </div>
        </div>
        <Link to="/entry-product">
        <div className="text-center m-auto px-3 py-2 rounded-md border-black shadow-md my-9 bg-orange-500  w-fit">
          NEW ARRIVAL
        </div>
        </Link>
      

        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-5">
          {prod && prod?.length < 0 ? (
            <>
              <div className="text-white flex justify-center items-center min-h-[40vh]">
                Please wait ...
              </div>
            </>
          ) : (
            prod?.slice(0,15).map((item: Product, id) => {
              const image = item.productImage[0]
              return (
                <div className="" key={id}>
                  <ProductCard
                    imageUrl={image}
                    productName={item.productName}
                    productPrice={item.productPrice}
                    onBuyNow={()=>handleBuyNow(item)}
                    
                  />
                </div>
              );
            })
          )}
        </div>
        <Link to='/entry-product'>
        <div className="text-center my-10 w-fit m-auto px-4 py-2 rounded-lg bg-blue-900 text-white">VIEW ALL</div>
        </Link>
      </div>
      <Curbside />
      <Footer />
    </div>
   </>}
   </>
  );
}

export default Home;

import { FaCarAlt, FaRegCalendarAlt } from "react-icons/fa";
import TestimonialCarousel from "./Textimony";
import { useNavigate } from "react-router-dom";
function Curbside() {
  const navigate = useNavigate()
  const handleRouteByCategory =(path:string)=>{
    navigate(`/get-product/${path}`)
  }
  return (
    <div className="py-16">
 

      <div className="text-start pl-5  text-[2rem]">
      Testimonials
      </div>

      <div className="">
      <TestimonialCarousel/>
      </div>

      <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center place-items-center gag-10 md:gag-16 lg:gag-24 flex-wrap">
        <div className="" onClick={() =>handleRouteByCategory("Hermès")}>
          <img src="/image/c1.png" alt="" className="h-[40]"/>
        </div>
        <div className="" onClick={() =>handleRouteByCategory("Zara")}>
          <img src="/image/c2.png" alt="" className="h-[40]"/>
        </div>
        <div className="" onClick={() =>handleRouteByCategory("Dior")}>
          <img src="/image/c3.png" alt="" className="h-[40]"/>
        </div>
        <div className="" onClick={() =>handleRouteByCategory("Louis-Vuitton")}>
          <img src="/image/c4.png" alt="" className="h-[40]"/>
        </div>

        <div className="" onClick={() =>handleRouteByCategory("Chanel")}>
          <img src="/image/c6.png" alt="" className="h-[40]"/>
        </div>
        <div className="" onClick={() =>handleRouteByCategory("Gucci")}>
          <img src="/image/c7.png" alt="" className="h-[40]"/>
        </div>
      </div>
    </div>
  );
}

export default Curbside;

// src/components/LuxuryBagsPro.tsx
import React from 'react';

const LuxuryBagsPro: React.FC = () => {
  return (
    <div className="px-[1rem] md:px-[10rem] my-[3rem]">
      <h1 className="text-center text-2xl font-bold mb-4">GET TO KNOW</h1>
      <h2 className="text-center text-3xl font-bold text-blue-600 mb-8">LUXURY BAGS PRO</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <img src="/image/a1.jpg" alt="Luxury Bag 1" className="w-full h-[50vh] object-cover" />
        <img src="/image/a2.jpg" alt="Luxury Bag 2" className="w-full h-[50vh] object-cover" />
        <img src="/image/a3.jpg" alt="Luxury Bag 3" className="w-full h-[50vh] object-cover" />
      </div>
      <p className="mt-8 text-gray-700 leading-8">
        Not only is Luxury Bags Pro a well-known name in the opulent realm of bags, but it is also a trailblazer 
        whose impact has changed the face of luxury fashion altogether. With more than 25 years of unrivaled 
        experience, LBP's journey has been marked by a strong love of luxury brands, an impressive collection 
        of rare items, and profound knowledge that has made it a global leader for visionary stylists, affluent 
        collectors, and elite auction houses. The world has come to appreciate and value luxury goods more 
        because of our vision and hard work, which have turned previously owned high-end objects into 
        sought-after gems. LBP is the go-to expert in the luxury domain because of its unmatched understanding 
        of the rich history and subtleties of the luxury market. It provides depth, accuracy, and a distinct 
        viewpoint that reveals the real meaning of luxury and collectibility.
      </p>
      <div className="mt-12">
        <img src="/image/a4.jpg" alt="Luxury Bag with Client" className="w-full h-[50vh] md:h-[85vh]" />
        <p className="mt-4 text-gray-700">
          Understanding the significance of unique requirements and tastes, Luxury Bags Pro provides highly tailored 
          services by adjusting advice to each individual client.
        </p>
        <p className="mt-4 text-gray-700">
          Whether our customers are wanting to purchase a Luxury bag for the first time, are interested in a particular 
          price range, or are seasoned collectors wishing to add to their amazing collection, LBP is fully dedicated to 
          supporting them on their individual path.
        </p>
      </div>
    </div>
  );
}

export default LuxuryBagsPro;

import { initializeApp } from "firebase/app";
import { Firestore, getFirestore } from 'firebase/firestore';
import { Auth, getAuth } from 'firebase/auth';

let isFirebaseInitialized = false;

const FirebaseApp = {
  apiKey: "AIzaSyBGYea6qzGkYxVx1xhTQGX0VrQ8wUQ8caw",
  authDomain: "luxury-bag-app-62cb3.firebaseapp.com",
  projectId: "luxury-bag-app-62cb3",
  storageBucket: "luxury-bag-app-62cb3.appspot.com",
  messagingSenderId: "321695868328",
  appId: "1:321695868328:web:0f93db5feaaff95d29a99d"
};

let database: Firestore; // Explicitly define as Firestore
let auth: Auth; // Explicitly define as Auth

const initializeFirebase = () => {
  if (!isFirebaseInitialized) {
    const store = initializeApp(FirebaseApp);
    database = getFirestore(store);
    auth = getAuth(store);
    isFirebaseInitialized = true;
  }
};


initializeFirebase();

export { auth, database };
